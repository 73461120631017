import React from 'react'
import LoginRoundedIcon from '@mui/icons-material/LoginRounded';
import "../../css/navigation/NaviButton.css"

export default function LoginButton() {
  return (
   <button className='navibutton'>
    <LoginRoundedIcon color="primary" fontSize="large"/>
    Login
  </button>
  )
}
