import React from 'react'
import NewTicketForm from './NewTicketForm'
import DetailsForm from './DetailsForm'
import '../../../css/display/NewTicket.css'

export default function NewTicketDisplay() {
  return (
    <div className='newTicketDisplay'>
      <NewTicketForm />
      <DetailsForm />
    </div>
  )
}
