import React from 'react'

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import GroupsIcon from '@mui/icons-material/Groups';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import EventIcon from '@mui/icons-material/Event';
import TerminalIcon from '@mui/icons-material/Terminal';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import SettingsEthernetIcon from '@mui/icons-material/SettingsEthernet';

import "../../../css/display/Calendar.css"

export default function NewEventSelect({ onChange }) {

  const [eventType, setEventType] = React.useState('Team Meeting');

  const handleChange = (event) => {
    onChange(event);
    setEventType(event.target.value);
  };

  return (
    <div className='neweventselect'>
        <Select
                    size="small"
                    id="type"
                    value={eventType}
                    onChange={handleChange}
                    variant = 'standard'
                    disableUnderline
                >
                <MenuItem value={'Team Meeting'}><GroupsIcon fontSize="large"/></MenuItem>
                <MenuItem value={'Private'}><AutoStoriesIcon fontSize="large"/></MenuItem>
                <MenuItem value={'Event'}><EventIcon fontSize="large"/></MenuItem>
                <MenuItem value={'Programming Session'}><TerminalIcon fontSize="large"/></MenuItem>
                <MenuItem value={'Important'}><PriorityHighIcon fontSize="large"/></MenuItem>
                <MenuItem value={'Other'}><SettingsEthernetIcon fontSize="large"/></MenuItem>
        </Select>
    <div>{eventType}</div>
  </div>
  )
}
