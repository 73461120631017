import React, {useState}  from 'react'
import ProjectBoardColumn from './ProjectBoardColumn'
import "../../../css/display/ProjectBoard.css"

export default function ProjectBoard({activateDetails, setTicketDetails}) {
  const testJSON = {'columns':['Active', 'Test', 'Review', 'Final']} //Testing
  const [activeButton, setActiveButton] = useState('');
  return (
    <div className='projectboard'>
        {testJSON.columns.map((column,i) => (<ProjectBoardColumn key={i}  number={i} name={column}  activeButton={activeButton} setActiveButton={setActiveButton} activateDetails={activateDetails} setTicketDetails={setTicketDetails}/>))}
    </div>
  )
}
