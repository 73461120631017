import React,  { useState } from 'react'
import Box from '@mui/material/Box';

import MyBoardTicketBar from './MyBoardTicketBar'
import MyBoardTicketCounter from './MyBoardTicketCounter';
import TicketDisplay from './TicketDisplay';
import '../../../css/display/MyBoard.css'

function generateTicketArray() { //for testing
  const ticketArray = [];
  let prio;

  for (let i = 0; i < 20; i++) {
    const random = Math.floor(Math.random() * 3);

    switch (random) {
      case 0:
        prio = 'high';
        break;
      case 1:
        prio = 'medium';
        break;
      default:
        prio = 'low';
        break;
    }
    ticketArray.push({ name: `ticket${i}`, prio });
  }

  return ticketArray;
}

export default function MyBoardDisplay() {
  const [ticketData, setTicketData] = useState({});
  const [ticketArray] = useState(generateTicketArray());

  return (
    <Box className ='myBoardDisplay'>
      <MyBoardTicketBar ticketArray={ticketArray} setTicketData={setTicketData}/>
      <MyBoardTicketCounter ticketArray={ticketArray}/>
      {(JSON.stringify(ticketData) !== '{}') && <TicketDisplay ticketData= {ticketData}/>}
    </Box>

  )
}
