import React from 'react'

import NewEvent from './NewEvent';

import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import GroupsIcon from '@mui/icons-material/Groups';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import EventIcon from '@mui/icons-material/Event';
import TerminalIcon from '@mui/icons-material/Terminal';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import SettingsEthernetIcon from '@mui/icons-material/SettingsEthernet';

import "../../../css/display/Calendar.css"

function renderIcon(type){
  switch(type){
    case 'Private': return <ListItemIcon><AutoStoriesIcon/></ListItemIcon>;
    case 'Programming Session': return <ListItemIcon><TerminalIcon /></ListItemIcon>; 
    case 'Team Meeting': return <ListItemIcon><GroupsIcon /></ListItemIcon>;
    case 'Important': return <ListItemIcon><PriorityHighIcon/></ListItemIcon>;
    case 'Other': return <ListItemIcon><SettingsEthernetIcon /></ListItemIcon>; 
    case 'Event': return <ListItemIcon><EventIcon /></ListItemIcon>;
    default: break;
  }
}
export default function CalendarDetails({timestamp}) {
  const [appointments, setAppointments] = React.useState([]);
  const [openIndex, setOpenIndex] = React.useState(null);

  const handleClick = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  
  const date = new Date(parseInt(timestamp));
  const formattedDate = (timestamp==='' || timestamp===null)?'Termine ':`Termine ${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
  
  return (
    <List 
      className='list'
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          {formattedDate}
          <NewEvent setAppointments={setAppointments}/>
        </ListSubheader>
      }>
      {console.log(appointments)}
      {appointments.map((item, index) => (
        <React.Fragment key={index}>
          <ListItemButton onClick={()=>handleClick(index)}>
            {renderIcon(item.type)}
            <ListItemText primary={item.time +" "+item.title} />
            {item.description && (openIndex === index ? <ExpandLess /> : <ExpandMore />)}
          </ListItemButton>
          {item.description && (
            <Collapse in={openIndex === index} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {item.description}
              </List>
            </Collapse>
          )}
        </React.Fragment>
      ))}
    </List>
  )
}
