import React from 'react'

import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';

import HomeIcon from '@mui/icons-material/Home';
import BusinessIcon from '@mui/icons-material/Business';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import "../../css/navigation/NaviButton.css"

export default function OnlineStatusButton() {

    const [location, setLocation] = React.useState('Business Office');
    const [checked, setChecked] = React.useState(true);
    const [itemColor, setItemColor] = React.useState('#66b6a4');

    const handleChange = (event) => {
        setLocation(event.target.value);
    };
    const handleSwitch = (event) => {
        setChecked(event.target.checked);
        setItemColor(event.target.checked ? '#66b6a4' : '#b87287');
    };

  return (
    <Box className='statusbutton'>
        <Box className='statusbutton-column'>
            <Box className='statusbutton-row'>
                <Select
                    size="small"
                    id="Location"
                    value={location}
                    onChange={handleChange}
                    variant = 'standard'
                    disableUnderline
                >
                <MenuItem value={'Home Office'}><HomeIcon sx={{ color: itemColor }} fontSize="large"/></MenuItem>
                <MenuItem value={'Business Office'}><BusinessIcon sx={{ color: itemColor }} fontSize="large"/></MenuItem>
                <MenuItem value={'On Road'}><DirectionsCarIcon sx={{ color: itemColor }} fontSize="large"/></MenuItem>
                </Select>
                <Box>{location}</Box>
            </Box>
                <Box className='statusbutton-row'>
                    <Switch
                        color="secondary" 
                        size="small"
                        checked={checked}
                        onChange={handleSwitch}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                    <Box sx={{ color: itemColor , fontSize: '1rem'}}>{checked?'Online':'Busy'}</Box>
                </Box>
        </Box>
    </Box>
  )
}
