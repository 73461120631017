import React from 'react'

import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const persons = [
  { name: 'Sai' },
  { name: 'Hasan' },
  { name: 'Waldemar'}
]
export default function NewEventParticipants({onChange}) {

    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;

    const handleSelectionChange = (event, selectedOptions) => {
      const selectedPersons = selectedOptions.map((option) => option.name);
      onChange(selectedPersons);
    };

  return (
    <Autocomplete 
        sx={{marginTop: '1rem'}}
        multiple
        id="checkboxes"
        options={persons}
        disableCloseOnSelect
        getOptionLabel={(option) => option.name}
        defaultValue={[persons[0]]}
        renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.name}
            </li>
        )}
        style={{ width: 500 }}
        renderInput={(params) => (
        <TextField {...params} label="Teilnehmer" />
        )}
        onChange={handleSelectionChange}
      />
  )
}
