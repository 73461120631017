import React, {useState} from 'react'
import ProjectBoardTicket from './ProjectBoardTicket';
import ColumnCounter from './ColumnCounter';
import "../../../css/display/ProjectBoard.css"

function generateTicketArray(number) { //for testing
  const ticketArray = [];
  const n = Math.floor(Math.random() * 10)+1;
  let prio;
  let desc = 'short description of ticket';

  for (let i = 0; i < n; i++) {
    const random = Math.floor(Math.random() * 3);

    switch (random) {
      case 0:
        prio = 'high';
        break;
      case 1:
        prio = 'medium';
        break;
      default:
        prio = 'low';
        break;
    }

    ticketArray.push({ name: `ticket ${number}.${i}`, prio, desc });
  }

  return ticketArray;
}

export default function ProjectBoardColumn({number, activeButton, setActiveButton, name, activateDetails, setTicketDetails}) {
  const [ticketArray] = useState(generateTicketArray(number));
  return (
    <div className='projectboard-column'>
        <h1>{name}</h1>
        <div className='projectboard-column inner'>
          {ticketArray.map((ticket, i) => (<ProjectBoardTicket activate={() => setActiveButton(`${ticket.name}`)} isActive={activeButton === `${ticket.name}`} key={i} activateDetails={activateDetails} setTicketDetails={setTicketDetails} name={ticket.name} prio={ticket.prio} desc={ticket.desc}/>))}
        </div>
        <ColumnCounter numberOfTickets={ticketArray.length}/>
    </div>
  )
}
