import React from 'react'
import LensBlurRoundedIcon from '@mui/icons-material/LensBlurRounded';
import "../../css/navigation/NaviButton.css"

export default function ManagementButton({ activate, isActive, setActiveComponent }) {

  const handleButtonClick = (componentName) => {
    activate();
    setActiveComponent(componentName);
  };

  return (
    <button onClick={() => handleButtonClick('ManagementDisplay')}  className={`navibutton ${isActive ? 'active' : ''}`}>
      <LensBlurRoundedIcon color="secondary" fontSize="large"/>
      Management
    </button>
  )
}
