import React, {useState} from 'react'

import NewEventTime from './NewEventTime';
import NewEventSelect from './NewEventSelect';
import NewEventParticipants from './NewEventParticipants';

import dayjs from 'dayjs';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded';

import "../../../css/display/Calendar.css"


export default function NewEvent({setAppointments}) {
  const [open, setOpen] = useState(false);
  const [appointmentData, setAppointmentData] = useState(getDefaultAppointment());

  function getDefaultAppointment() {
    return {
      title: "Appointment",
      description: "",
      type: "Team Meeting",
      time: "08:00",
      participants: [],
    };
  }
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCreation = () => {
    setAppointments((prevAppointments) => {
      const newAppointment = {
        title: appointmentData.title,
        description: appointmentData.description,
        type: appointmentData.type,
        time: appointmentData.time,
        participants: appointmentData.participants
      };
  
      const updatedAppointments = [...prevAppointments, newAppointment];
      
      // Sort the appointments array based on the "time" key
      updatedAppointments.sort((a, b) => {
        const timeA = a.time;
        const timeB = b.time;
  
        // Compare the time values
        if (timeA < timeB) {
          return -1; // a should be placed before b
        } else if (timeA > timeB) {
          return 1; // a should be placed after b
        } else {
          return 0; // a and b have the same order
        }
      });
      return updatedAppointments;
    });
    console.log("created:");
    console.log(appointmentData);
    setAppointmentData(getDefaultAppointment());
    setOpen(false);
  };

  const handleTimeChange = (newTime) => {
    const formattedTime = dayjs(newTime).format('HH:mm');
    setAppointmentData((prevState) => ({
      ...prevState,
      time: formattedTime,
    }));
  };

  const handleTypeChange = (event) => {
    const value = event.target.value;
    setAppointmentData((prevData) => ({
      ...prevData,
      type: value,
    }));
  };

  const handleParticipantsChange = (event) => {
    console.log("xxx  "+event.length);
    console.log("xxx  "+event);
    setAppointmentData((prevData) => ({
      ...prevData,
      participants: event, 
    }));
    console.log(appointmentData);
  };

  const handleInputChange = (event) => {
    const { id, value } = event.target;
    setAppointmentData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };


  const buttonStyle = {
    'fontFamily': 'Montserrat, sans-serif',
    'fontSize': '1.3rem',
    'color': 'rgb(60, 61, 61);',
    'textTransform': 'none',
    '&:hover': {
      backgroundColor: 'lightgray',
      padding: 0,
      borderBottom: 'none',
    },
    '&.MuiButton-root': {
      padding: 0,
      borderBottom: 'none',
    }
  }

  return (
    <div>
    <Button sx={buttonStyle} onClick={handleClickOpen}>
      <AddBoxRoundedIcon  color= 'secondary' sx={{fontSize:'30px', marginRight: '2px'}}/>
      Neues Event
    </Button>
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Erstelle einen Termin</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Hier folgt eine Funktion, die Termine, Meetings und andere Ereignisse erstellen kann.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="title"
          label="Termin"
          fullWidth="false"
          variant="standard"
          inputProps={{ maxLength: 50 }}
          onChange={handleInputChange}
        />
        <TextField
          autoFocus
          margin="dense"
          id="description"
          label="Beschreibung"
          fullWidth
          variant="standard"
          multiline
          inputProps={{ maxLength: 650 }}
          onChange={handleInputChange}
        />
        <NewEventTime onChange={handleTimeChange}/>
        <NewEventSelect onChange={handleTypeChange}/>
        <NewEventParticipants onChange={handleParticipantsChange}/>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Abbrechen</Button>
        <Button startIcon={<AddBoxRoundedIcon color= 'secondary'/>} onClick={handleCreation}>Erstellen</Button>
      </DialogActions>
    </Dialog>
    </div>
  )
}
