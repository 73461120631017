import React from 'react'
import '../../../css/display/MyBoard.css'

export default function TicketDisplayHeader({ticketName, prio}) {
  return (
    <div className='ticketDisplay-header'>
        <h1>{ticketName}</h1>
        <h1>{prio}</h1>
    </div>
  )
}
