import React from 'react';

export default function ImageUpload({ onImageSelect }) {
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        onImageSelect(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div>
      <input
        type="file"
        accept="image/png,image/jpeg,image/gif"
        onChange={handleImageChange}
      />
    </div>
  );
}