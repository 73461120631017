import axios from 'axios';

const taskApi = axios.create({
    baseURL: '/',
    headers: {
      'Content-Type': 'application/json',
    },
});

export const getTickets = async (params) => {
    const response = await taskApi.get('/task/ticket', { params });
    return response;
};
export const getClassifications = async () => {
   const response = await taskApi.get('/task/classification');
    return response;
};
export const getPriorities = async () => {
    const response = await taskApi.get('/task/priority');
    return response;
};
export const getPhases = async () => {
    const response = await taskApi.get('/task/phase');
    return response;
};
export const getProjects = async () => {
    const response = await taskApi.get('/task/project');
    return response;
};
export const getSkills = async () => {
    const response = await taskApi.get('/task/skill');
    return response;
};
export const getMilestones = async () => {
    const response = await taskApi.get('/task/milestone');
    return response;
};
export const getComments = async () => {
    const response = await taskApi.get('/task/comment');
    return response;
};
export const getAmendment = async () => {
    const response = await taskApi.get('/task/amendment');
    return response;
};