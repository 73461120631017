import React from 'react'
import TextField from '@mui/material/TextField';

import '../../../css/display/NewTicket.css';

export default function FormInputField() {
  const [text, setText] = React.useState('');

  const handleTextChange = (event) => {
    setText(event.target.value);
  };

  return (
    <TextField
      className="description"
      value={text}
      onChange={handleTextChange}
      sx={{ height:'50%', maxHeight:'50%'}}
      multiline
      rows={20}
      maxRows={20}
  />
  )
}
