import React from 'react'
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import "../../css/navigation/NaviButton.css"

export default function MyProfileButton({ activate, isActive, setActiveComponent }) {

  const handleButtonClick = (componentName) => {
    activate();
    setActiveComponent(componentName);
  };

  return (
    <button onClick={() => handleButtonClick('MyProfileDisplay')}  className={`navibutton ${isActive ? 'active' : ''}`}>
      <PersonRoundedIcon color="secondary" fontSize="large"/>
      Mein Profil
    </button>
  )
}
