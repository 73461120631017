import React from 'react'
import '../../../css/display/MyBoard.css'

export default function MyBoardTicketCounter({ticketArray}) {
    var highPrios = 0;
    var mediumPrios = 0;
    var lowPrios = 0;

    for(let i = 0; i < ticketArray.length; i++){
        let ticket = ticketArray[i];
        let prio = ticket.prio;
        switch(prio){
            case 'low':
                lowPrios++;
                break;
            case 'medium':
                mediumPrios++;
                break;
            case 'high':
                highPrios++;
                break;
            default:
        }
    }
  return (
    <div className='ticketCounter'>
        <h2 className='counterPrio high'> High Prio: {highPrios}</h2>
        <h2 className='counterPrio medium'>Medium Prio: {mediumPrios}</h2>
        <h2 className='counterPrio low'>Low Prio: {lowPrios}</h2>
        <h2 className='counterPrio'>Anzahl der Tickets insgesamt: {ticketArray.length}</h2>
    </div>
  )
}
