import React from 'react'
import ViewColumnRoundedIcon from '@mui/icons-material/ViewColumnRounded';
import "../../css/navigation/NaviButton.css"

export default function ProjectBoardButton({ activate, isActive, setActiveComponent }) {

  const handleButtonClick = (componentName) => {
    activate();
    setActiveComponent(componentName);
  };

  return (
    <button onClick={() => handleButtonClick('ProjectBoardDisplay')}  className={`navibutton ${isActive ? 'active' : ''}`}>
      <ViewColumnRoundedIcon color="secondary" fontSize="large"/>
      Projekt Board
      </button>
  )
}
