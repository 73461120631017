import React from 'react'
import "../../../css/display/ProjectBoard.css"

export default function ProjectBoardText({ticketDetails}) {
  return (
    <div className='projectboardDetails-text'>
        <div className='text-content'>
            <div className='text-content header'>{ticketDetails.name}</div>
            <div className='text-content inner'>{ticketDetails.desc}</div>
            <div className='text-content footer'>{ticketDetails.prio}</div>
        </div>
    </div>
  )
}
