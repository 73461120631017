import React from 'react';
import FormHeader from './FormHeader';
import FormInputfield from './FormInputfield';
import FormParameters from './FormParameters';
import MemberList from './MemberList';
import '../../../css/display/NewTicket.css';

export default function NewTicketForm() {
  return (
    <div className='newTicketForm'>
        <FormHeader />
        <FormInputfield />
        <FormParameters />
        <MemberList />
    </div>
  )
}
