import React from 'react'
import "../../../css/display/ProjectBoard.css"

export default function ProjectBoardTicket({activate, isActive, activateDetails, setTicketDetails, name, prio, desc}) {
  const handleOnClick = () =>{
    activate();
    activateDetails(true);
    const details = {};
    details.name = name;
    details.desc = desc;
    details.prio = prio;
    setTicketDetails(details)
  }
  return (
    <button className={`projectboard-ticket ${isActive ? 'active' : ''}`} onClick={handleOnClick}>
      <div className='ticket-content'>
        <h1>{name}</h1>
        <h2>{desc}</h2>
        <h2>{prio}</h2>
      </div>
      
    </button>
  )
}
