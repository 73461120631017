import React from 'react'

import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import '../../../css/display/NewTicket.css';


const owners = [{name:"ProductOwnerA"},{name:"ProductOwnerB"},{name:"ProductOwnerC"}]
const asignees = [{name:"AsigneeA"},{name:"AsigneeB"},{name:"AsigneeC"}]
const skills = [
  { skill: 'Java' },
  { skill: 'MongoDB' },
  { skill: 'C++'}
]
export default function FormParameters() {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const defaultProps = {
    options: owners,
    getOptionLabel: (option) => option.name,
  };
  const defaultAsigneeProps = {
    options: asignees,
    getOptionLabel: (option) => option.name,
  };
  return (
    <Grid container rowSpacing={1} columnSpacing={2}>
    <Grid item xs={6}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer  sx={{paddingTop:'0px'}} components={['DatePicker', 'DatePicker', 'DatePicker']}>
          <DatePicker/>
        </DemoContainer>
      </LocalizationProvider>
      <Autocomplete
        {...defaultProps}
        id="productowner"
        clearOnEscape
        renderInput={(params) => (
          <TextField {...params} label="Productowner" variant="standard" />
        )}
      />
      <Autocomplete
        {...defaultAsigneeProps}
        id="asignee"
        clearOnEscape
        renderInput={(params) => (
          <TextField {...params} label="Asignee" variant="standard" />
        )}
      />
    </Grid>
    <Grid item xs={6}>
      <Autocomplete 
        sx={{ overflowY: "auto" }}
        limitTags={5}
        multiple
        id="skills"
        options={skills}
        disableCloseOnSelect
        getOptionLabel={(option) => option.skill}
        renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                checked={selected}
              />
              {option.skill}
            </li>
          )}
          renderInput={(params) => (
            <TextField {...params} label="Skills" />
          )}
        />
    </Grid>
  </Grid>
  )
}
