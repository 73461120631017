import React, { useState } from 'react'
import Avatar from '@mui/material/Avatar';
import ImageUpload from './ImageUpload';
import '../../../css/display/MyProfile.css'

export default function ProfileImage() {
  const [imageData, setImageData] = useState(null);

  const handleImageSelect = (dataUrl) => {
    setImageData(dataUrl);
  };
  return (
    <div className='image'>
    {imageData ? (
      <Avatar
        alt="User"
        src={imageData}
        sx={{ width: 256, height: 256 }}
        variant="rounded"
      />
    ) : (
      <ImageUpload onImageSelect={handleImageSelect} />
    )}
    <h1 className='name'>Name Nachname</h1>
  </div>
  )
}
