import React from 'react'
import ViewStreamRoundedIcon from '@mui/icons-material/ViewStreamRounded';
import "../../css/navigation/NaviButton.css"

export default function MyBoardButton({ activate, isActive, setActiveComponent }) {

  const handleButtonClick = (componentName) => {
    activate();
    setActiveComponent(componentName);
  };
  return (
    
    <button onClick={() => handleButtonClick('MyBoardDisplay')} className={`navibutton ${isActive ? 'active' : ''}`}>
     <ViewStreamRoundedIcon  color="secondary" fontSize="large"/>
      Mein Board
    </button>
  )
}
