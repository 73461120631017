import React from 'react'
import "../../css/navigation/NaviButton.css"

export default function Logo({ activate,setActiveComponent }) {

  const handleButtonClick = (componentName) => {
    activate();
    setActiveComponent(componentName);
  };

  return (
    <button onClick={() => handleButtonClick('Logo')} className='logobutton'><img  src={process.env.PUBLIC_URL + "/skiim_cloud01.png"} alt="Skiim"></img>Skiim</button>
  )
}
