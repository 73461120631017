import React from 'react'
import ProfileImage from './ProfileImage'
import ProfileBar from './ProfileBar'
import Contact from './Contact'
import Skills from './Skills'
import Expertise from './Expertise'
import Certificates from './Certificates'
import '../../../css/display/MyProfile.css'

export default function MyProfileDisplay() {
  return (
    <div className='myProfile'>
      <ProfileImage/>
      <ProfileBar/>
      <Contact/>
      <div className='bar'><Skills/><Expertise/></div>
      <Certificates/>
    </div>
  )
}
