import React, {useState} from 'react'

import ProjectBoard from './ProjectBoard'
import ProjectBoardDetails from './ProjectBoardDetails'
import "../../../css/display/ProjectBoard.css"

export default function ProjectBoardDisplay() {
  const [detailsActive, activateDetails] = useState(false);
  const [ticketDetails, setTicketDetails] = useState({});
  return (
    <div className='projectboardDisplay'>
      <ProjectBoard activateDetails={activateDetails} setTicketDetails={setTicketDetails}/>
      <ProjectBoardDetails detailsActive={detailsActive} ticketDetails={ticketDetails}/>
    </div>
  )
}
