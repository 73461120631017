import React from 'react'
import '../../../css/display/MyBoard.css'

export default function TicketDisplayContent() {
  return (
    <div className='ticketDisplay-content'>Content Content Content Content Content Content Content Content Content Content Content 
    Content Content Content Content Content Content Content Content Content Content Content Content Content Content Content Content 
    Content Content Content Content Content Content Content Content Content Content Content Content Content Content Content Content 
    Content Content Content Content Content Content Content Content Content Content </div>
  )
}
