import React from 'react'
import Diversity3RoundedIcon from '@mui/icons-material/Diversity3Rounded';
import "../../css/navigation/NaviButton.css"

export default function TeamButton({ activate, isActive, setActiveComponent }) {

  const handleButtonClick = (componentName) => {
    activate();
    setActiveComponent(componentName);
  };

  return (
    <button onClick={() => handleButtonClick('TeamDisplay')}  className={`navibutton ${isActive ? 'active' : ''}`}>
      <Diversity3RoundedIcon color="secondary" fontSize="large"/>
      Team
    </button>
  )
}
