import { useEffect,useState} from "react";
import { useNavigate } from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { blue, pink, deepPurple } from '@mui/material/colors';
import Grid from '@mui/material/Grid';
import NaviBar from './components/navigation/NaviBar';
import DataDisplay from './components/display/data/DataDisplay';
import MyBoardDisplay from './components/display/myBoard/MyBoardDisplay';
import ProjectBoardDisplay from './components/display/projectboard/ProjectBoardDisplay';
import TeamDisplay from './components/display/team/TeamDisplay';
import CalendarDisplay from './components/display/calendar/CalendarDisplay';
import MyProfileDisplay from './components/display/myProfile/MyProfileDisplay'
import ManagementDisplay from './components/display/management/ManagementDisplay';
import SystemDisplay from './components/display/system/SystemDisplay';
import NewTicketDisplay from './components/display/newTicket/NewTicketDisplay';

import './App.css'

const lightTheme = createTheme({
  palette: {
    primary: {main: deepPurple[100]},
    secondary: {main: blue[500]},
    mode: 'light',
  },
});

const darkTheme = createTheme({
  palette: {
    primary: {main: blue[700]},
    secondary: {main: pink[700]},
    mode: 'dark',
  },
});

function App() {
  //SignInSide-Login
  const navigate=useNavigate();
  const [authenticated, setauthenticated] = useState(false);
  useEffect(() => {
    const loggedInUser = localStorage.getItem("authenticated");
    if (loggedInUser) {
      setauthenticated(loggedInUser);
    }
  }, []);

  const [activeComponent, setActiveComponent] = useState('DataDisplay');
  const [isDarkMode] = useState(false);

  const theme = isDarkMode ? darkTheme : lightTheme;

  const renderComponent = () => {
    switch (activeComponent) {
      case 'MyBoardDisplay':
        return <MyBoardDisplay />;
      case 'ProjectBoardDisplay':
        return <ProjectBoardDisplay />;
      case 'TeamDisplay':
        return <TeamDisplay />;
      case 'CalendarDisplay':
        return <CalendarDisplay />;
      case 'MyProfileDisplay':
        return <MyProfileDisplay />;
      case 'ManagementDisplay':
        return <ManagementDisplay />;
      case 'SystemDisplay':
        return <SystemDisplay />;
      case 'NewTicketDisplay':
        return <NewTicketDisplay />;
      default:
        return <DataDisplay />;
    }
  };
  if (!authenticated) {
    navigate("/login");
  } else {
  return (
    <ThemeProvider theme={theme}>
      <Grid container>
        <Grid item xs={3}>
          <NaviBar setActiveComponent={setActiveComponent}/>
        </Grid>
        <Grid item xs={6}>
          {renderComponent()}
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
}

export default App;
