import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import SignInSide from './components/login/SignInSide';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { BrowserRouter, Route, Routes } from 'react-router-dom';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId="401809233846-rg6lnke4drjck7i2h08j5hc69okoetks.apps.googleusercontent.com">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<SignInSide />}> </Route>
          <Route path="/login" element={<SignInSide />}> </Route>
          <Route path="/app" element={<App />}></Route>
        </Routes>
      </BrowserRouter>
    </GoogleOAuthProvider>
  </React.StrictMode>
);