import React, {useRef, useState} from 'react'
import MyBoardTicket from './MyBoardTicket'
import '../../../css/display/MyBoard.css'

export default function MyBoardTicketBar({ticketArray, setTicketData}) {

const [activeButton, setActiveButton] = useState('');
const handleActiveButton = (button) => {
    setActiveButton(button);
};

const ticketBarRef = useRef(null);

const handleMouseWheel = (event) => {
  if (ticketBarRef.current) {
    event.preventDefault();
    const delta = Math.max(-1, Math.min(1, event.deltaY));
    ticketBarRef.current.scrollLeft -= delta * 460;
  }
};
  return (
    <div className='myBoardTicketBar'  ref={ticketBarRef} onWheel={handleMouseWheel}>
       {ticketArray.map((ticket,i)=>(<MyBoardTicket activate={() => handleActiveButton(`${i}`)} isActive={activeButton === `${i}`} key={i} ticketName={ticket.name} prio={ticket.prio} setTicketData={setTicketData}/>))}
    </div>
  )
}
