import React from 'react'
import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded';
import "../../css/navigation/NaviButton.css"

export default function NewTicketButton({ activate, isActive, setActiveComponent }) {
  const handleButtonClick = (componentName) => {
    activate();
    setActiveComponent(componentName);
  };
  return (
    <button onClick={() => handleButtonClick('NewTicketDisplay')}  className={`navibutton ${isActive ? 'active' : ''}`}>
      <AddBoxRoundedIcon color="secondary" fontSize="large"/>
      Neues Ticket
    </button>
  )
}
