import React from 'react'
import ProjectBoardText from './ProjectBoardText'
import "../../../css/display/ProjectBoard.css"

export default function ProjectBoardDetails({detailsActive, ticketDetails}) {
  return (
    <div className='projectboardDetails'>
      {detailsActive && <ProjectBoardText ticketDetails={ticketDetails}/>}
    </div>
  )
}
