import React, { useState } from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import Calendar from './Calendar'
import CalendarDetails from './CalendarDetails'

export default function CalendarDisplay() {

  const [timestamp, setTimestamp] = useState('');

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid  container  direction="column" justifyContent="center" alignItems="center">
        <Grid item xs={6}>
          <Calendar setTimestamp={setTimestamp} />
        </Grid>
        <Grid item xs={6} sx={{marginTop:'5px'}} >
          <CalendarDetails timestamp={timestamp} />
        </Grid>
      </Grid>
    </Box>
  )
}
