import React from 'react'
import TicketDisplayHeader from './TicketDisplayHeader'
import TicketDisplayFooter from './TicketDisplayFooter'
import TicketDisplayContent from './TicketDisplayContent'
import '../../../css/display/MyBoard.css'

export default function TicketDisplay({ticketData}) {
  return (
    <div className='ticketDisplay'>
        <TicketDisplayHeader ticketName={ticketData.ticketName} prio={ticketData.prio}/>
        <TicketDisplayContent />
        <TicketDisplayFooter />
    </div>
  )
}
