import React from 'react'
import '../../../css/display/MyBoard.css'

export default function MyBoardTicket({activate, isActive, ticketName, prio, setTicketData}) {

  const handleClick = () => {
    const data = {};
    data.ticketName = ticketName;
    data.prio = prio;
    setTicketData(data);
    activate();
  };

  return (
    <button className={`myBoardTicket ${isActive ? 'active' : ''}`} onClick={handleClick}>
        <h1>{ticketName}</h1>
        <h2 className={`ticketPrio ${prio? prio:''}`}>{prio}</h2>
    </button>
  )
}
