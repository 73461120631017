import React from 'react';
import MemberDetails from './MemberDetails';
import CreationButton from './CreationButton';
import '../../../css/display/NewTicket.css';

export default function DetailsForm() {
  return (
    <div className='detailsForm'>
      <MemberDetails />
      <CreationButton />
    </div>
  )
}
