import React from 'react'

import 'dayjs/locale/de';
import 'dayjs/locale/en-gb';
import dayjs from 'dayjs';
import Grid from '@mui/material/Grid';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

const locales = ['de', 'en'];

export default function NewEventTime({ time, onChange }) {
  const [locale, setLocale] = React.useState('de');

  return (
    <Grid container alignItems="center" spacing={2} sx={{ padding: '11px' }}>
        <Grid item xs={3}>
            <ToggleButtonGroup
                value={locale}
                exclusive
                fullWidth
                onChange={(event, newLocale) => setLocale(newLocale)}
            >
            {locales.map((localeItem) => (
                <ToggleButton key={localeItem} value={localeItem}>
                    {(localeItem === 'de')?'24h':'am/pm'}
                </ToggleButton>
                ))}
            </ToggleButtonGroup>
            </Grid>
            <Grid item xs={9}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
                <TimePicker
                label="Uhrzeit"
                defaultValue={dayjs().set('hour', 8).set('minute', 0)}
                onChange={onChange}
                />
            </LocalizationProvider>
        </Grid>
    </Grid>
  )
}
