import React, { useEffect, useRef }  from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import 'dayjs/locale/de';

import "../../../css/display/Calendar.css"


export default function Calendar({setTimestamp, setAppointments}) {

  const containerRef = useRef(null);
  const handleContainerClick = useRef(null);

  useEffect(() => {
    var current = containerRef.current;
    handleContainerClick.current = (event) => {
      const target = event.target;
      if (target.matches('.MuiPickersDay-dayWithMargin') || target.matches('.MuiPickersDay-today')) {
        const timestamp = target.dataset.timestamp;
        setTimestamp(timestamp);
      }
    };

    containerRef.current.addEventListener('click', handleContainerClick.current);

    const defaultTimestamp = current.querySelector('.MuiPickersDay-today').dataset.timestamp;
    setTimestamp(defaultTimestamp);

    return () => {
      current.removeEventListener('click', handleContainerClick.current);
    };
  }, [setTimestamp]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
      <div ref={containerRef}>
        <DateCalendar displayWeekNumber={true} className='calendar'/>
      </div>
    </LocalizationProvider>
  )
}
