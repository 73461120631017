import React from 'react'
import '../../../css/display/MyProfile.css'

export default function ProfileBar() {
  return (
    <div className='bar'>
        <h1 className='element'>Rolle: Entwickler</h1>
        <h1 className='element'>Team: Backend</h1>
        <h1 className='element'>Projekt: LoRa</h1>
    </div>
  )
}
