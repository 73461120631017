import * as React from 'react';

import OnlineStatusButton from './OnlineStatusButton';
import MyBoardButton from './MyBoardButton';
import ProjectBoardButton from './ProjectBoardButton';
import TeamButton from './TeamButton';
import CalendarButton from './CalendarButton';
import MyProfileButton from './MyProfileButton';
import ManagementButton from './ManagementButton';
import SystemButton from './SystemButton';
import LoginButton from './LoginButton';
import NewTicketButton from './NewTicketButton';
import Logo from './Logo';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

import '../../css/navigation/NaviBar.css'

const drawerWidth = 260;

export default function NaviBar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [activeButton, setActiveButton] = React.useState('');

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleActiveButton = (button) => {
    setActiveButton(button);
  };


  const drawer = (
    <Box className = 'sidebar'>
      <Logo activate={() => handleActiveButton('dashboard')} setActiveComponent={props.setActiveComponent}/>
      <Divider sx={{ borderBottomWidth: 2 }}/>
      <OnlineStatusButton />
      <Divider sx={{ borderBottomWidth: 2 }}/>
      <MyBoardButton activate={() => handleActiveButton('MyBoardButton')} isActive={activeButton === 'MyBoardButton'} setActiveComponent={props.setActiveComponent}/>
      <ProjectBoardButton activate={() => handleActiveButton('ProjectBoardButton')} isActive={activeButton === 'ProjectBoardButton'} setActiveComponent={props.setActiveComponent}/> 
      <TeamButton activate={() => handleActiveButton('TeamButton')} isActive={activeButton === 'TeamButton'} setActiveComponent={props.setActiveComponent}/>
      <CalendarButton activate={() => handleActiveButton('CalendarButton')} isActive={activeButton === 'CalendarButton'} setActiveComponent={props.setActiveComponent}/>
      <MyProfileButton activate={() => handleActiveButton('MyProfileButton')} isActive={activeButton === 'MyProfileButton'} setActiveComponent={props.setActiveComponent}/>
      <NewTicketButton activate={() => handleActiveButton('NewTicketButton')} isActive={activeButton === 'NewTicketButton'} setActiveComponent={props.setActiveComponent} />
      <Divider sx={{ borderBottomWidth: 2, width: '100%'}}/>
      <ManagementButton activate={() => handleActiveButton('ManagementButton')} isActive={activeButton === 'ManagementButton'} setActiveComponent={props.setActiveComponent}/>
      <SystemButton activate={() => handleActiveButton('SystemButton')} isActive={activeButton === 'SystemButton'} setActiveComponent={props.setActiveComponent}/>
      <LoginButton setActiveComponent={props.setActiveComponent}/>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={handleDrawerToggle}
        sx={{ mr: 2, display: { sm: 'none' } }}
      >
        <MenuIcon fontSize="large"/>
      </IconButton>
      <Box
        component="navigation"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="navigation-elements"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}