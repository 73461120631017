import React from 'react'
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import '../../../css/display/NewTicket.css';

function getColor(value){
  switch(value){
    case 'low': return 'rgb(156, 197, 250)';
    case 'high': return 'rgb(219, 107, 150)';
    default: return 'rgba(98, 187, 168, 0.904)';
  }
}
export default function FormHeader() {

  const [name, setName] = React.useState('');
  const [type, setType] = React.useState('feature');
  const [priority, setPriority] = React.useState('medium');
  const [project, setProject] = React.useState('projectA');
  const [color, setColor] = React.useState('rgba(98, 187, 168, 0.904)');

  const handleNameChange = (event) => {
    setName(event.target.value);
  };
  const handleTypeChange = (event) => {
    setType(event.target.value);
  };
  const handlePriorityChange = (event) => {
    let value = event.target.value;
    setColor(getColor(value));
    setPriority(event.target.value);
  };
  const handleProjectChange = (event) => {
    setProject(event.target.value);
  };

  return (
    <div 
      className="formHeader" 
      style={{ display: 'flex', justifyContent: 'space-between'}}
    >
    <TextField
      inputProps={{ maxLength: 35 }}
      className="inputfield"
      label="Ticket Name"
      value={name}
      onChange={handleNameChange}
      sx={{ width: '20rem', maxWidth: '20rem'}}
    />
    <Select
      sx={{ width: '8rem', maxWidth: '8rem'}}
      value={type}
      onChange={handleTypeChange}
    >
      <MenuItem value="feature">Feature</MenuItem>
      <MenuItem value="bug">Bug</MenuItem>
      <MenuItem value="patch">Patch</MenuItem>
    </Select>
    <Select sx={{ width: '8rem', maxWidth: '8rem', color: color}} value={priority} onChange={handlePriorityChange}>
      <MenuItem sx={{ color: 'rgb(156, 197, 250)'}} value="low">Low</MenuItem>
      <MenuItem sx={{ color: 'rgba(98, 187, 168, 0.904)'}} value="medium">Medium</MenuItem>
      <MenuItem sx={{ color: 'rgb(219, 107, 150)'}} value="high">High</MenuItem>
    </Select>
    <Select   sx={{ width: '11rem', maxWidth: '11rem', fontWeight: 'bold' }} value={project} onChange={handleProjectChange}>
      <MenuItem value="projectA">ProjectA</MenuItem>
      <MenuItem value="projectB">ProjectB</MenuItem>
    </Select>
  </div>
  )
}
